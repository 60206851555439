<template>
  <div class="toggle-switch">
    <input
      type="checkbox"
      :id="id"
      :checked="checked"
      @change="toggle"
      class="toggle-switch-checkbox"
    />
    <label :for="id" class="toggle-switch-label">
      <span class="toggle-switch-inner"></span>
      <span class="toggle-switch-switch"></span>
    </label>
    <span class="toggle-switch-text">
      <slot>Toggle</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: {
    checked: Boolean,
    id: {
      type: String,
      default: () => `toggle-switch-${Math.random()}`,
    },
  },
  methods: {
    toggle(event) {
      this.$emit("change", event.target.checked);
    },
  },
};
</script>

<style scoped lang="scss">
.toggle-switch {
  display: inline-flex;
  position: relative;
  align-items: center;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-text {
  color: var(--Text-Body, #373737);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: -0.24px;
}

.toggle-switch-label {
  display: block;
  width: 30px;
  height: 12px;
  background-color: #b8b8b8;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
  margin-right: 8px;
}

.toggle-switch-inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #b8b8b8;
  border-radius: 20px;
  transition: background-color 0.3s;
}

.toggle-switch-switch {
  position: absolute;
  top: -2.7px;
  left: -2px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  background-color: #1b6163;
  opacity: 0.5;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  background-color: #1b6163;
  transform: translateX(15px);
}
</style>
