import { render, staticRenderFns } from "./button.component.vue?vue&type=template&id=d99caef4&scoped=true"
import script from "./button.component.vue?vue&type=script&lang=js"
export * from "./button.component.vue?vue&type=script&lang=js"
import style0 from "./button.component.vue?vue&type=style&index=0&id=d99caef4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d99caef4",
  null
  
)

export default component.exports