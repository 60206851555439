import Vue from "vue";
import Vuex from "vuex";
import exchangeProduct from "./modules/exchange-product.module.js";
import reasonData from "./modules/reason.module.js";
import addressData from "./modules/address.module.js";
import deviceType from "./modules/device-type.module.js";
import cart from "./modules/cart.module.js";
import paymentLink from "./modules/payment-link.module.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    exchangeProduct,
    reasonData,
    addressData,
    deviceType,
    cart,
    paymentLink
  },
});

export default store;
