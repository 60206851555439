<template>
  <div id="product-exchange-wrapper">
    <div v-if="this.canExchange">
      <ExchangeButton />
    </div>
  </div>
</template>

<script>
import ExchangeButton from "./components/exchange-button/exchange-button.component.vue";
import { getExchangeBtnStatus } from "./services/exchange.service";
import { extractShipmentId } from "./utils/helpers";
import store from "./store";

export default {
  name: "App",
  components: {
    ExchangeButton,
  },
  data() {
    return {
      shipmentId: null,
      canExchange: false,
      canCancel: false,
    };
  },
  provide() {
    return {
      store,
    };
  },
  async mounted() {
    const shipmentId = extractShipmentId();
    try{
      const { data } = await getExchangeBtnStatus(shipmentId);
      const btnStatuses = data?.data;
      this.canExchange = btnStatuses?.can_exchange;
      this.canCancel = btnStatuses?.can_cancel_exchange;
    }catch(err){
      console.log("Failed To Fetch Button Statuses")
    }
  },
};
</script>

<style lang="scss">
#product-exchange-wrapper {
  box-sizing: border-box;
  height: 100%;

  > div {
    height: 100%;
  }
}
</style>
