<template>
  <div class="multi-step-form-outer-container">
    <div v-if="showIntroAnimation" class="exchange-intro-animation">
      <div class="intro-gif-Container" @animationend="hideIntroAnimation">
        <div class="inner-circle">
          <img :src="exchangeIntroAnimationGif" alt="exchange_intro" />
        </div>
      </div>
      <div class="intro-header">
        <h3 class="intro-heading">Exchange Made Simple</h3>
        <h3 class="intro-subheading">Fast, easy, and worry-free swaps</h3>
      </div>
      <div class="exchange-intro-icons">
        <div
          v-for="(icon, index) in exchangeIcons"
          :key="index"
          class="intro-icon-item"
        >
          <!-- Display the icon's logo -->
          <img :src="icon.logo" :alt="icon.text" class="intro-icon-logo" />

          <!-- Display the icon's text -->
          <p class="intro-icon-text" v-html="icon.text"></p>
        </div>
      </div>
    </div>

    <div v-show="!showIntroAnimation" class="panel-container">
      <!-- LEFT PANEL -->
      <div class="stepper">
        <Step
          v-for="(step, index) in steps"
          :key="index"
          :isActive="currentStep === index"
          :isCompleted="step.completed"
          :stepNumber="index + 1"
        >
          <!--Todo: Find fix to move to a new file -->
          <!-- HEADER FOR STEPS -->
          <template v-slot:step-heading>
            <div class="header">
              <div :class="{ 'heading-wrapper': isMobile }">
                <h5 class="step-heading">
                  {{ step.title }}
                </h5>
                <div
                  v-if="isMobile && step.completed && !(currentStep === index)"
                  class="text"
                  :title="getStepData(step.key)?.tooltip"
                >
                  {{ getStepData(step.key)?.display }}
                </div>
              </div>
              <div
                v-if="isMobile && step.completed && !(currentStep === index)"
              >
                <img
                  :src="editIcon"
                  alt="edit-icon"
                  @click="toggleStep(index)"
                  class="edit-icon"
                />
              </div>
              <div
                v-if="!isMobile && step.completed && !(currentStep === index)"
                class="edit-wrapper"
              >
                <p class="text" :title="getStepData(step.key)?.tooltip">
                  {{ getStepData(step.key)?.display }}
                </p>
                <img
                  :src="editIcon"
                  alt="edit-icon"
                  @click="toggleStep(index)"
                  class="edit-icon"
                />
              </div>
              <div
                v-else-if="
                  currentStep === index &&
                  (step.key === 'pickup' || step.key === 'delivery')
                "
              >
                <button
                  class="add-address-button"
                  @click="toggleShowEditAddress"
                >
                  <img :src="plusIcon" alt="plus-icon" class="plus-icon" />
                  <span>Add new address</span>
                </button>
              </div>
            </div>
          </template>

          <!-- STEP COMPONENT -->
          <component
            :is="step.component"
            v-if="currentStep === index"
            :key="step.component"
            :isDeliverySameAsPickup="isDeliverySameAsPickup"
            :refreshAddresses="refreshAddresses"
            @selectExchangeProduct="handleExchangeProductChange"
            @complete="completeStep(index)"
            @toggleIsDeliverySameAsPickup="toggleIsDeliverySameAsPickup"
            @toggleShowEditAddress="toggleShowEditAddress"
            @toggleRefreshAddresses="toggleRefreshAddresses"
            @open-payment-link="openPaymentLink"
          />
        </Step>

        <div v-if="showEditAddressScreen" class="add-address">
          <AddAddress
            :addressType="addressType"
            @toggleShowEditAddress="toggleShowEditAddress"
            @toggleRefreshAddresses="toggleRefreshAddresses"
          />
        </div>
      </div>

      <!-- RIGHT PANEL -->
      <div class="product-details-panel">
        <div>
          <!-- OLD PRODUCT  -->
          <productDetailsCard
            v-bind:is="correctResponsiveComponent"
            :data="this.cardDataReturn"
          />

          <div
            class="exchange-divider fade-in-animation"
            :class="{ show: showExchangeProduct }"
            v-if="showExchangeProduct"
          >
            <span>Exchanging with</span>
            <img src="../../../../assets/down-arrow.svg" alt="down-arrow" />
          </div>

          <!-- NEW PRODUCT -->
          <div
            v-if="showExchangeProduct"
            class="fade-in-animation"
            :class="{ show: showExchangeProduct }"
          >
            <productDetailsCard
              v-bind:is="correctResponsiveComponent"
              :data="this.cardDataExchange"
            />
          </div>
        </div>

        <div class="exchange-icons">
          <div
            v-for="(icon, index) in exchangeIcons"
            :key="index"
            class="icon-item"
          >
            <!-- Display the icon's logo -->
            <img :src="icon.logo" :alt="icon.text" class="icon-logo" />

            <!-- Display the icon's text -->
            <p class="icon-text" v-html="icon.text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Step from "../../../../common/step/step.component.vue";
import ReasonSelection from "../reason-selection/reason-selection.component.vue";
import PickupAddressSelection from "../pickup-address-selection/pickup-address-selection.component.vue";
import DeliveryAddressSelection from "../delivery-address-selection/delivery-address-selection.component.vue";
import VariantSizeSelection from "../variant-size-selection/variant-size-selection.component.vue";
import PriceBreakup from "../price-breakup/price-breakup.component.vue";
import ProductDetailsCardDesktop from "../../../../common/product-details-card/product-details-card-desktop.vue";
import ProductDetailsCardMobile from "../../../../common/product-details-card/product-details-card-mobile.vue";
import AddAddress from "../add-address/add-address.component.vue";

import { getDayAndMonth } from "../../../../utils/helpers";
import {
  isUndefinedNullOrEmpty,
  getTrimmedString,
} from "../../../../utils/helpers.js";

import EditIcon from "../../../../assets/edit-icon.svg";
import PlusIcon from "../../../../assets/plus-icon.svg";
import SecurePaymentLogo from "../../../../assets/secure_logo.svg";
import FastShippingLogo from "../../../../assets/fast_shipping_logo.svg";
import QualityLogo from "../../../../assets/quality_logo.svg";
import CustomerSupportLogo from "../../../../assets/customer_support_logo.svg";
import ExchangeIntroAnimation from "../../../../assets/exchange_intro.gif";
import { ADDRESS_TYPE } from "../../../../utils/constants";

export default {
  name: "MultiStepForm",
  inject: ["store"],
  components: {
    Step,
    ProductDetailsCardDesktop,
    ProductDetailsCardMobile,
    PriceBreakup,
    AddAddress,
  },
  data() {
    return {
      steps: [
        {
          title: "Select a reason to exchange",
          component: ReasonSelection,
          completed: false,
          key: "reason",
        },
        {
          title: "Select Pickup Address",
          component: PickupAddressSelection,
          completed: false,
          key: "pickup",
        },
        {
          title: "Select Delivery Address",
          component: DeliveryAddressSelection,
          completed: false,
          key: "delivery",
        },
        {
          title: "Select Exchange Product",
          component: VariantSizeSelection,
          completed: false,
          key: "variant",
        },
        {
          title: "Confirm Exchange",
          component: PriceBreakup,
          completed: false,
          key: "confirm",
        },
      ],
      exchangeIcons: [
        {
          logo: SecurePaymentLogo,
          text: "100% Secure<br>Payments",
        },
        {
          logo: FastShippingLogo,
          text: "Super Fast <br>Shipping",
        },
        {
          logo: QualityLogo,
          text: "100% Premium <br>Quality",
        },
        {
          logo: CustomerSupportLogo,
          text: "24/7 Customer <br>Support",
        },
      ],
      exchangeIntroAnimationGif: ExchangeIntroAnimation,
      showIntroAnimation: true, // Control whether the intro animation is visible
      currentStep: 0,
      productDetailsCard: {
        DESKTOP: "ProductDetailsCardDesktop",
        MOBILE: "ProductDetailsCardMobile",
      },
      isMobile: false,
      cardDataReturn: {
        type: "RETURN_PRODUCT",
        productImg: "",
        name: "",
        desc: "",
        size: "",
        quantity: 1,
        price: "",
        exchange_eligibility_date: "",
      },
      cardDataExchange: {
        type: "EXCHANGE_PRODUCT",
        productImg: "",
        name: "",
        desc: "",
        size: "",
        quantity: 1,
        price: "",
        exchange_eligibility_date: "",
      },
      isDeliverySameAsPickup: true,
      showEditAddressScreen: false,
      editIcon: EditIcon,
      plusIcon: PlusIcon,
      showExchangeProduct: false,
      refreshAddresses: false,
    };
  },
  computed: {
    correctResponsiveComponent() {
      if (this.isMobile) return this.productDetailsCard.MOBILE;
      return this.productDetailsCard.DESKTOP;
    },
    addressType() {
      const addressTypeKey = this.steps[this.currentStep]?.key;
      switch (addressTypeKey) {
        case "pickup":
          return ADDRESS_TYPE.PICKUP;
        case "delivery":
          return ADDRESS_TYPE.DELIVERY;
        default:
          return ADDRESS_TYPE.DELIVERY;
      }
    },
  },
  watch: {
    isDeliverySameAsPickup(newVal) {
      this.filterDeliveryAddressStep(newVal);
    },
  },
  methods: {
    hideIntroAnimation() {
      this.showIntroAnimation = false;
    },
    handleExchangeProductChange(details) {
      // Store in Desired Format
      const { price, delivery_promise } =
        this.store.state.exchangeProduct.newExchangeProductPriceAndStore;

      if (details) {
        const formattedData = {
          type: "EXCHANGE_PRODUCT",
          productImg: details?.selectedVariant?.medias[0]?.url ?? "",
          name: details?.selectedVariant?.brand?.name,
          desc: details?.selectedVariant?.name,
          size: details?.selectedSize?.value,
          quantity: 1,
          price: price?.currency_symbol + price?.effective,
          exchange_eligibility_date: getDayAndMonth(delivery_promise?.max),
        };
        this.cardDataExchange = formattedData;
        this.showExchangeProduct = true;
      } else {
        this.showExchangeProduct = false;
      }
    },
    filterDeliveryAddressStep(isDeliveryAddressSame) {
      if (isDeliveryAddressSame) {
        this.steps = this.steps.filter(
          (step) => step.component !== DeliveryAddressSelection
        );
      } else {
        const deliveryStepIndex = this.steps.findIndex(
          (step) => step.component === DeliveryAddressSelection
        );
        if (deliveryStepIndex === -1) {
          this.steps.splice(2, 0, {
            title: "Select Delivery Address",
            component: DeliveryAddressSelection,
            completed: false,
            key: "delivery",
          });
        }
      }
    },
    toggleStep(index) {
      if (this.currentStep === index) {
        this.currentStep = null;
      } else {
        this.currentStep = index;
      }
    },
    completeStep(index) {
      if (index === this.steps.length - 1)
        this.$emit("change-component", "StepThree");
      this.steps[index].completed = true;
      this.currentStep = index + 1;
    },
    toggleIsDeliverySameAsPickup() {
      this.isDeliverySameAsPickup = !this.isDeliverySameAsPickup;
    },
    toggleShowEditAddress() {
      this.showEditAddressScreen = !this.showEditAddressScreen;
    },
    toggleRefreshAddresses(value) {
      this.refreshAddresses = value;
    },
    getStepData(key) {
      switch (key) {
        case "reason": {
          const text = this.store.state.reasonData.selectedReasonText;
          return {
            tooltip: text,
            display: getTrimmedString(text),
          };
        }
        case "pickup": {
          const text =
            this.store.state.addressData.selectedPickupAddress.name +
            ", " +
            this.store.state.addressData.selectedPickupAddress.address;
          const phone =
            this.store.state.addressData.selectedPickupAddress.phone;
          return {
            tooltip: text,
            display: getTrimmedString(text) + " " + phone,
          };
        }
        case "delivery": {
          const text =
            this.store.state.addressData.selectedDeliveryAddress.name +
            ", " +
            this.store.state.addressData.selectedDeliveryAddress.address;
          const phone =
            this.store.state.addressData.selectedDeliveryAddress.phone;
          return {
            tooltip: text,
            display: getTrimmedString(text) + " " + phone,
          };
        }
        case "variant": {
          const name =
            this.store.state.exchangeProduct.newExchangeProductVariant.name;

          const size =
            this.store.state.exchangeProduct.newExchangeProductSize.value;
          return {
            tooltip: `Variant: ${name}    Size: ${size}`,
            display: `Variant: ${getTrimmedString(name)}    Size: ${size}`,
          };
        }
        default:
          return "";
      }
    },
    openPaymentLink() {
      this.$emit("change-component", "StepFour");
    },
  },
  mounted() {
    this.isMobile = this.store.state.deviceType.isMobile;

    this.filterDeliveryAddressStep(this.isDeliverySameAsPickup);

    if (
      !isUndefinedNullOrEmpty(this.store.state.exchangeProduct.selectedProduct)
    ) {
      // Get Return Product Card Details from Store
      const selectedProductDetails =
        this.store.state.exchangeProduct.selectedProduct;

      this.cardDataReturn.name = selectedProductDetails?.brand;
      this.cardDataReturn.desc = selectedProductDetails?.name;

      this.cardDataReturn.size = selectedProductDetails?.size;
      this.cardDataReturn.productImg =
        selectedProductDetails?.images?.[0] ?? "";
      this.cardDataReturn.price =
        selectedProductDetails?.currency?.currency_symbol +
        selectedProductDetails?.price;
      this.cardDataReturn.exchange_eligibility_date = getDayAndMonth(
        selectedProductDetails?.exchangeWindowDetails?.exchangeExpiry
      );
    }
  },
};
</script>

<style scoped lang="scss">
.multi-step-form-outer-container {
  position: relative;

  .exchange-intro-animation {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 50px 300px 100px 300px;

    @media screen and (max-width: 768px) {
      margin: 40px 20px 0 20px;
      gap: 22px;
    }

    .intro-gif-Container {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background: #f7fcfa;
      padding: 7px 28px 49px 28px;
      animation: verticalUp 1s ease-in-out;

      @media screen and (max-width: 768px) {
        width: 146px;
        height: 146px;
        padding: 5px 20px 35px 20px;
      }

      .inner-circle {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background: white;

        @media screen and (max-width: 768px) {
          width: 106px;
          height: 106px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }

    .intro-header {
      animation: verticalDown 1s ease-in-out;

      .intro-heading {
        color: #121212;
        font-family: unset;
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.36px;
        margin-bottom: 8px;
      }

      .intro-subheading {
        color: #121212;
        font-family: unset;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.28px;
      }
    }

    .exchange-intro-icons {
      display: flex;
      gap: 16px;
      background: #f9f9f9;
      animation: verticalUp 1s ease-in-out;
      padding: 24px 48px;
      border-radius: 56px;

      @media screen and (max-width: 768px) {
        border-radius: 16px;
      }

      .intro-icon-item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;

        .intro-icon-logo {
          width: 27px;
          height: 27px;
        }

        .intro-icon-text {
          color: #6a6a6a;
          text-align: center;
          font-family: unset;
          font-size: 10px;
          font-weight: 400;
          letter-spacing: -0.046px;
        }
      }
    }
  }

  .panel-container {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }

    .stepper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      margin: 24px 32px;
      background: #f3f3f7;
      width: auto;
      flex: 1;
      width: 560px;
      height: 560px;
      overflow-y: auto;
      position: relative;
      z-index: 1;

      .add-address {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background: #f3f3f7;
      }

      @media screen and (max-width: 768px) {
        margin: 8px 16px;
        width: auto;
        height: auto;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .edit-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;
        }

        .heading-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }

        .text {
          color: var(--Text-Label, var(--Text-Placeholder-Text, #747474));
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
          white-space: pre;
        }

        .edit-icon {
          padding: 0 8px;
          cursor: pointer;
        }

        .add-address-button {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 6px;

          border: none;
          outline: none;
          background: none;
          cursor: pointer;

          color: #1b6163;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }
      }

      .step-heading {
        color: var(--Text-Heading, #121212);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.28px;

        @media screen and (max-width: 768px) {
          font-size: 12px;
          letter-spacing: -0.24px;
        }
      }
    }

    .product-details-panel {
      background: white;
      padding: 24px 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; /* Distribute space between items */

      .fade-in-animation {
        opacity: 0;
        transform: translateY(-10px);
        animation: fadeIn 0.5s forwards ease-in-out; // Animation timing can be adjusted

        &.show {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
          transform: translateY(-10px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @media screen and (max-width: 768px) {
        padding: 8px 16px;
        width: 100%;
      }

      .exchange-divider {
        margin: 12px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
          margin: 4px 0;
        }

        span {
          color: #747474;
          font-family: unset;
          font-size: 12px;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: -0.24px;

          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .exchange-icons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            display: none;
        }

        .icon-item {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;

          .icon-logo {
            width: 24px;
            height: 24px;
          }

          .icon-text {
            color: var(--buttonPrimary, #6a6a6a);
            text-align: center;
            font-family: unset;
            font-size: 10px;
            font-weight: 400;
            letter-spacing: -0.046px;
          }
        }
      }
    }
  }

  @keyframes verticalUp {
    0% {
      opacity: 0;
      transform: translateY(50px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End in the original position */
    }
  }

  @keyframes verticalDown {
    0% {
      opacity: 0;
      transform: translateY(-50px); /* Start from above */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End in the original position */
    }
  }
}
</style>
