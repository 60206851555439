const state = {
  selectedReason: null,
  selectedSubReason: null,
  uploadedImages: [],
  selectedReasonText: "",
  getReasonData: null,
};

const mutations = {
  SET_SELECTED_REASON_DATA(state, payload) {
    state.selectedReason = payload.selectedReason;
    state.selectedSubReason = payload.selectedSubReason;
    state.uploadedImages = payload.uploadedImages;
    state.selectedReasonText = payload.selectedReasonText;
  },
  SET_REASON_DATA(state, payload) {
    state.getReasonData = payload;
  },
  RESET_STATE(state) {
    state.selectedReason = null;
    state.selectedSubReason = null;
    state.uploadedImages = [];
    state.selectedReasonText = "";
    state.getReasonData = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
