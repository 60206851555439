<template>
  <div v-if="cardData" class="mobile-card-container">
    <div class="card-title">
      {{ cardTitle }}
    </div>
    <div class="card-content">
      <div class="product-img-container">
        <img :src="cardData.productImg" alt="product-img" />
      </div>
      <div class="product-content">
        <div class="product-title">
          <div class="product-text-container">
            <span class="product-name">{{ cardData.name }}</span>
            <span class="product-desc">{{ cardData.desc }}</span>
          </div>
          <div class="price">
            {{ cardData.price }}
          </div>
        </div>
        <div class="size-qty-container">
          <div class="size-qty-text-container">
            <span class="size-text">Size: {{ cardData.size }}</span>
            <span class="quantity-text">Qty: {{ cardData.quantity }}</span>
          </div>
          
          <div v-if="returnPolicyUrl.length > 0" class="view-policy" target="_blank"><a :href="returnPolicyUrl" >View Policy</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductDetailsCardMobile",
  inject: ["store"],
  props: {
    data: {
      default: null,
    },
  },
  computed: {
    cardData() {
      return this.data;
    },
    cardTitle() {
      if (this.data.type === "RETURN_PRODUCT") return "Returning Item";
      return "Exchange Product";
    },
    returnPolicyUrl(){
      return this.store.state.exchangeProduct.returnPolicyUrl;
    }
  },
};
</script>

<style scoped lang="scss">
.mobile-card-container {
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #f3f3f7;
  margin: 0;

  .card-title {
    color: #747474;
    font-family: unset;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.24px;
    padding: 6px 12px;
    border-bottom: 1px solid #e1e1e1;
  }

  .card-content {
    padding: 6px 12px;
    display: flex;
    gap: 8px;

    .product-img-container {
      width: 31px;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: contain;
      }
    }

    .product-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .product-title {
        display: flex;
        justify-content: space-between;

        .product-text-container {
          display: flex;
          gap: 4px;

          .product-name {
            color: #747474;
            font-family: unset;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.24px;
          }

          .product-desc {
            color: #373737;
            font-family: unset;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.24px;
          }
        }

        .price {
          color: #121212;
          font-family: unset;
          font-size: 12px;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: -0.24px;
        }
      }

      .size-qty-container {
        display: flex;
        justify-content: space-between;

        .size-qty-text-container {
          display: flex;
          gap: 4px;

          .size-text {
            color: #747474;
            font-family: unset;
            font-size: 10px;
            font-weight: 500;
            line-height: 140%;
          }

          .quantity-text {
            color: #747474;
            font-family: unset;
            font-size: 10px;
            font-weight: 500;
            line-height: 140%;
          }
        }

        .view-policy {
          color: #1b6163;
          font-family: unset;
          font-size: 10px;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: -0.2px;
        }
      }
    }
  }
}
</style>
