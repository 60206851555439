import { render, staticRenderFns } from "./variant-size-selection.component.vue?vue&type=template&id=2326c851&scoped=true"
import script from "./variant-size-selection.component.vue?vue&type=script&lang=js"
export * from "./variant-size-selection.component.vue?vue&type=script&lang=js"
import style0 from "./variant-size-selection.component.vue?vue&type=style&index=0&id=2326c851&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2326c851",
  null
  
)

export default component.exports