import axios from "axios";
import { proxyUrl } from "../utils/constants.js";

const headers = {
  "Content-Type": "application/json",
  "x-currency-code": "INR",
};

const uploadImage = async (file) => {
  try {
    const startUploadResponse = await axios.post(
      `/ext/${proxyUrl}/application/api/v1/upload/start`,
      {
        content_type: file.type,
        size: file.size,
      },
      { headers }
    );

    if (startUploadResponse && startUploadResponse.data) {
      try {
        await axios.put(startUploadResponse.data.data.upload.url, file, {
          withCredentials: false,
          headers: {
            "Content-Type": file.type,
          },
        });

        try {
          const completeUploadResponse = await axios.post(
            `/ext/${proxyUrl}/application/api/v1/upload/complete`,
            startUploadResponse.data.data
          );

          return completeUploadResponse.data;
        } catch (completeError) {
          console.error("Error completing upload:", completeError);
          throw completeError;
        }
      } catch (uploadError) {
        console.error("Error uploading file:", uploadError);
        throw uploadError;
      }
    } else {
      throw new Error("Invalid start upload response");
    }
  } catch (startError) {
    console.error("Error starting upload:", startError);
    throw startError;
  }
};

export default uploadImage;
