<template>
  <fdk-pincode>
    <template slot-scope="pincode">
      <div class="add-address-container">
        <div class="header">
          <div @click="handleBackClick" class="back-button">
            <img :src="leftArrowIcon" alt="left-arrow" />
          </div>
          <h2 class="title">{{ title }}</h2>
        </div>
        <form @submit.prevent="submitForm" class="form">
          <div class="form-group">
            <input
              type="text"
              v-model="form.name"
              placeholder="Full name"
              class="form-input"
              required
            />
            <input
              type="number"
              v-model="form.phone"
              placeholder="Phone no."
              class="form-input"
              inputmode="numeric"
              required
            />
          </div>
          <div class="form-group">
            <div class="input-container">
              <input
                type="number"
                v-model="form.pincode"
                placeholder="Pincode"
                class="pincode-input"
                inputmode="numeric"
                required
              />
              <button
                class="location-button"
                @click="useCurrentLocation(pincode)"
                type="button"
              >
                <img :src="locationIcon" alt="use-current-location" />
                <!-- Can add this for Desktop View <span>Use my Current LocationIcon</span> -->
              </button>
            </div>
          </div>
          <div class="form-group">
            <textarea
              v-model="form.address"
              placeholder="House/Flat No., Road name, Area / Colony / Landmark"
              class="form-textarea"
              required
            ></textarea>
          </div>
          <div class="form-group">
            <input
              type="text"
              v-model="form.city"
              placeholder="City"
              class="form-input"
              required
            />
            <input
              type="text"
              v-model="form.state"
              placeholder="State"
              class="form-input"
              required
            />
          </div>
          <div class="save-address">
            <label class="label">Save address as:</label>
            <div class="address-types">
              <button
                v-for="type in addressTypes"
                :key="type"
                type="button"
                @click="setAddressType(type.name)"
                :class="{
                  'address-type-button': true,
                  active: form.addressType === type.name,
                }"
              >
                <img :src="type.icon" :alt="`${type.name}-icon`" />
                <span>{{ type.name }}</span>
              </button>
            </div>
          </div>
          <div class="error" v-if="formError.length">
            {{ formError }}
          </div>
          <Button
            :button-text="addressFormBtnText"
            :button-class-type="'primary'"
            :aria-label="'save-address-button'"
            @button-click="submitForm(pincode)"
          />
        </form>
      </div>
    </template>
  </fdk-pincode>
</template>

<script>
import Button from "../../../../common/button/button.component.vue";
import HomeIcon from "../../../../assets/home-icon.svg";
import OfficeIcon from "../../../../assets/office-icon.svg";
import LocationIcon from "../../../../assets/location-icon.svg";
import LeftArrowIcon from "../../../../assets/left-arrow-icon.svg";
import CurrentLocation from "../../../../assets/current-location.svg";
import {
  isUndefinedNullOrEmpty,
  getCountryName,
  getCountryPhoneCode,
  capitalizeFirstLetter,
} from "../../../../utils/helpers.js";
import {
  addAddress,
  editAddress,
} from "../../../../services/address.service.js";
import { ADDRESS_TYPE } from '../../../../utils/constants';

export default {
  name: "AddAddress",
  inject: ["store"],
  components: {
    Button,
  },
  props:{
    addressType:{
      type:String,
      default: ADDRESS_TYPE.DELIVERY
    }
  },
  data() {
    return {
      leftArrowIcon: LeftArrowIcon,
      title: "Add new address",
      isEditAddress: false,
      locationIcon: CurrentLocation,
      formError: "",
      form: {
        addressId: undefined,
        name: "",
        pincode: "",
        address: "",
        addressType: "Home",
        city: "",
        state: "",
        phone: "",
        country: undefined,
        country_phone_code: undefined,
      },
      addressTypes: [
        { icon: HomeIcon, name: "Home" },
        { icon: OfficeIcon, name: "Office" },
        { icon: LocationIcon, name: "Other" },
      ],
    };
  },
  computed:{
    addressFormBtnText(){
      switch(this.addressType){
        case ADDRESS_TYPE.PICKUP :return "Pickup Here";
        case ADDRESS_TYPE.DELIVERY : return "Deliver Here";
        default: return "Deliver Here";
      }
    }
  },
  mounted() {
    const editAddress = this.store.state.addressData.editAddress;
    this.formError = "";

    if (!isUndefinedNullOrEmpty(editAddress)) {
      this.isEditAddress = true;
      this.title = "Edit address";

      this.form.name = editAddress?.name ?? "";
      this.form.pincode = editAddress?.area_code ?? "";
      this.form.address = editAddress?.address ?? "";
      this.form.addressType =
        capitalizeFirstLetter(editAddress?.address_type) ?? "";
      this.form.city = editAddress?.city ?? "";
      this.form.state = editAddress?.state ?? "";
      this.form.phone = editAddress?.phone ?? "";
      this.form.country = editAddress?.country ?? "";
      this.form.country_phone_code = editAddress?.country_phone_code ?? "";
      this.form.addressId = editAddress?.id ?? "";
    }
  },
  methods: {
    async useCurrentLocation(pincode) {
      try {
        const isValidPincode = await this.validatePincode(pincode);

        if (!isValidPincode) {
          this.formError = "Pincode is not valid";
          return;
        } else {
          this.formError = "";

          const pincodeDetails = await pincode.pinCodeDetails(
            this.form.pincode
          );

          if (pincodeDetails?.details) {
            this.form.city = pincodeDetails?.details?.city ?? "";
            this.form.state = pincodeDetails?.details?.state ?? "";
            this.form.country = pincodeDetails?.details?.country ?? "";
          }
        }
      } catch (err) {
        console.error("Error in validating and getting pincode details ", err);
      }
    },
    setAddressType(type) {
      this.form.addressType = type;
    },
    async validatePincode(pincode) {
      try {
        const isValidPincode = await pincode.validatePincode(this.form.pincode);

        return isValidPincode ?? false;
      } catch (err) {
        console.error(err);
      }
      return false;
    },
    async submitForm() {
      // Form Validation
      if (
        !isUndefinedNullOrEmpty(this.form.name) &&
        this.form.name.length === 0
      ) {
        this.formError = "Please Enter Name";
        return;
      }

      if (
        !isUndefinedNullOrEmpty(this.form.phone) &&
        this.form.phone.length === 0
      ) {
        this.formError = "Please Enter Phone no.";
        return;
      }

      if (
        !isUndefinedNullOrEmpty(this.form.pincode) &&
        this.form.pincode.length === 0
      ) {
        this.formError = "Please Enter Pincode";
        return;
      }

      if (
        !isUndefinedNullOrEmpty(this.form.address) &&
        this.form.address.length === 0
      ) {
        this.formError = "Please Enter Address";
        return;
      }

      if (
        !isUndefinedNullOrEmpty(this.form.city) &&
        this.form.city.length === 0
      ) {
        this.formError = "Please Enter City";
        return;
      }

      if (
        !isUndefinedNullOrEmpty(this.form.state) &&
        this.form.state.length === 0
      ) {
        this.formError = "Please Enter State";
        return;
      }

      this.formError = "";

      // Handling form submission
      if (!this.isEditAddress) {
        const country = getCountryName();
        const country_phone_code = getCountryPhoneCode();

        this.form.country = country;
        this.form.country_phone_code = country_phone_code;

        try {
          const body = {
            address: {
              ...this.form,
              area_code: this.form.pincode,
            },
            address_type: this.form.addressType,
          };

          const addressRes = await addAddress(body);

          this.$emit("toggleRefreshAddresses", true);

          if (addressRes) {
            this.$emit("toggleShowEditAddress", false);
          }
        } catch (err) {
          console.error("Error in adding address", err);
        }
      } else {
        try {
          const body = {
            address: {
              ...this.form,
              area_code: this.form.pincode,
            },
            address_type: this.form.addressType,
          };

          const addressRes = await editAddress(body, this.form.addressId);

          if (addressRes) {
            this.store.commit("addressData/SET_EDIT_ADDRESS", {});

            this.$emit("toggleRefreshAddresses", true);

            this.$emit("toggleShowEditAddress", false);
          }
        } catch (err) {
          console.error("Error in editing address", err);
        }
      }
    },
    handleBackClick() {
      this.store.commit("addressData/SET_EDIT_ADDRESS", {});

      this.$emit("toggleShowEditAddress", false);
    },
  },
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.add-address-container {
  padding: 24px 32px;
  background: #fff;
  border-radius: 12px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    .back-button {
      cursor: pointer;
    }

    .title {
      color: var(--Text-Heading, #121212);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.48px;
    }
  }

  .input-container {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e1e1e1;
    background: var(--Theme-White, #fff);
    padding: 16px 12px;
    width: 100%;

    .pincode-input {
      border: none;
      outline: none;
      width: inherit;
    }

    .pincode-input::-webkit-inner-spin-button,
    .pincode-input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    .location-button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;

    .form-group {
      display: flex;
      gap: 12px;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .save-address {
      display: flex;
      flex-direction: column;
      gap: 1px;

      .label {
        color: var(--Text-Body, #4e4e4e);
        margin-bottom: 4px;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }

      .address-types {
        display: flex;
        align-items: center;
        gap: 4px;

        .address-type-button {
          display: flex;
          padding: 8px 12px;
          align-items: center;
          gap: 10px;

          border-radius: 4px;
          border: 1px solid
            var(--Overlay-and-Popup-Dividers-and-Strokes, #e1e1e1);
          background: var(--Theme-White, #fff);

          color: var(--Text-Body, #4e4e4e);

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.24px;
        }

        .address-type-button.active {
          border-color: 1px solid var(--buttonPrimary, #4e3f09);
          /* background: rgba(var(--buttonPrimary), 0.8); */
          color: var(--buttonPrimary, #4e3f09);
        }
      }
    }

    .form-input {
      border-radius: 8px;
      border: 1px solid #e1e1e1;
      background: var(--Theme-White, #fff);
      padding: 16px 12px;
      width: 100%;

      .form-input::-webkit-inner-spin-button,
      .form-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }

    .form-textarea {
      border-radius: 8px;
      border: 1px solid #e1e1e1;
      background: var(--Theme-White, #fff);
      padding: 8px 12px;
      width: 100%;
      height: 100px;
      resize: none;
    }

    .form-textarea::placeholder {
      color: #747474;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
    }

    .form-input::placeholder {
      color: #747474;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
    }
  }
}

.error {
  color: #f00;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
}
</style>
