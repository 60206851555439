import axios from "axios";
import { proxyUrl } from "../utils/constants.js";

export const getExchangeBtnStatus = async (shipment_id)=>{
  return axios.get(
    `/ext/${proxyUrl}/application/api/v1/exchanges/status?shipment_id=${shipment_id}`
  );
}

export const getExchangeStatus = async (shipment_id) => {
  return axios.get(
    `/ext/${proxyUrl}/application/api/v1/exchanges?shipment_id=${shipment_id}`
  );
};

export const placeExchangeProductOrder = async (data) => {
  return axios.post(`/ext/${proxyUrl}/application/api/v1/exchanges`, {
    ...data,
  });
};
