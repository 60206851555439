<template>
  <div
    class="address-card"
    @click="selectAddress"
    :class="{ selected: isSelected }"
  >
    <div class="right-tick" v-if="isSelected">
      <img :src="rightTickIcon" alt="right-tick" />
    </div>
    <div class="header">
      <div class="details">
        <strong class="name">{{ name }}</strong>
        <span class="phone-number">{{ phone }}</span>
        <span class="badge">{{ label }}</span>
      </div>
      <div>
        <img
          :src="editIcon"
          alt="edit-icon"
          aria-label="edit-address"
          @click="editAddress"
        />
      </div>
    </div>
    <div class="address">{{ address }}</div>
    <div class="confirm-address" v-if="isSelected">
      <Button
        :button-text="buttonText"
        :show-arrow-icon="true"
        :button-class-type="'primary'"
        :aria-label="'confirm-address'"
        :isDisabled="this.isLoading"
        @button-click="confirmAddress"
      />
    </div>
  </div>
</template>

<script>
import EditIcon from "../../assets/edit-icon.svg";
import RightTickIcon from "../../assets/right-tick-icon.svg";
import Button from "../button/button.component.vue";

export default {
  name: "AddressCard",
  components: {
    Button,
  },
  data() {
    return {
      editIcon: EditIcon,
      rightTickIcon: RightTickIcon,
      isLoading: false,
    };
  },
  props: {
    name: String,
    phone: String,
    address: String,
    label: String,
    isSelected: Boolean,
    buttonText: String,
    pincode:String
  },
  methods: {
    selectAddress() {
      this.$emit("select");
    },
    confirmAddress() {
      this.$emit("confirm");
    },
    editAddress() {
      this.$emit("edit");
    },
  },
};
</script>

<style scoped lang="scss">
.address-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: var(--Theme-White, #fff);
  padding: 12px;
  cursor: pointer;
  position: relative;

  .right-tick {
    position: absolute;
    right: -8px;
    top: -8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .address {
    color: var(--Text-Label, var(--Text-Placeholder-Text, #747474));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: -0.24px;
    margin-top: 4px;
  }

  .confirm-address {
    margin-top: 8px;
  }

  .details {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;

    .name {
      color: var(--Text-Heading, #121212);

      font-size: 14px;
      font-style: normal;
      font-weight: 00;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
    }

    .phone-number {
      color: var(--Text-Label, var(--Text-Placeholder-Text, #747474));

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;
    }

    .badge {
      display: flex;
      padding: 2px 4px;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: var(--Overlay-and-Popup-Highlighter, #f3f3f7);

      color: var(--Text-Body, #373737);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.24px;
      text-transform: capitalize;
    }
  }
}

.badge {
  background-color: #e0e0e0;
  padding: 2px 5px;
  border-radius: 3px;
}

.selected {
  border: 1px solid var(--buttonPrimary, #4e3f09);
}
</style>
