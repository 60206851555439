<template>
  <div class="step" :class="{ active: isActive, completed: isCompleted }">
    <div class="step-header">
      <div class="heading-wrapper">
        <div v-if="isCompleted">
          <img :src="completeTickIcon" alt="complete" />
        </div>
        <div class="step-number" v-else>{{ stepNumber }}</div>
        <slot name="step-heading"></slot>
      </div>
    </div>
    <div class="step-content" v-if="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import CompleteTickIcon from "../../assets/complete-tick-icon.svg";
import EditIcon from "../../assets/edit-icon.svg";

export default {
  name: "StepForm",
  data() {
    return {
      completeTickIcon: CompleteTickIcon,
      editIcon: EditIcon,
    };
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
    stepNumber: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.step {
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  width: -webkit-fill-available;
  opacity: 0.3;
  pointer-events: none;
}

.active {
  opacity: 1;
  pointer-events: all;
}

.step.completed {
  opacity: 1;
  pointer-events: all;
}

.step-header {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  .heading-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
}

.step-number {
  color: #1b6163;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.24px;

  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: #f3f3f7;
}

.step-content {
  font-size: 16px;
}
</style>
