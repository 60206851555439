import axios from "axios";
import { proxyUrl } from "../utils/constants.js";

export const createCart = async (payload) => {
  return axios.post(`/ext/${proxyUrl}/application/api/v1/cart`, {
    ...payload,
  });
};

export const checkoutCart = async (payload) => {
  return axios.post(`/ext/${proxyUrl}/application/api/v1/cart/checkout`, {
    ...payload,
  });
};
