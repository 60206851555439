const state = {
    isMobile:false,
};

const mutations = {
  SET_IS_MOBILE(state, payload) {
    state.isMobile = payload;
  },
  RESET_STATE(state) {
    state.isMobile = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
