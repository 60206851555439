import { render, staticRenderFns } from "./address-card.component.vue?vue&type=template&id=6b638000&scoped=true"
import script from "./address-card.component.vue?vue&type=script&lang=js"
export * from "./address-card.component.vue?vue&type=script&lang=js"
import style0 from "./address-card.component.vue?vue&type=style&index=0&id=6b638000&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b638000",
  null
  
)

export default component.exports