<template>
  <div class="exchange-btn-container">
    <Button
      :button-text="'Exchange'"
      :button-class-type="'secondary'"
      :aria-label="'exchange-button'"
      :aria-described-by="'exchange-product-button'"
      :is-disabled="false"
      @button-click="handleExchangeClick"
    >
      Exchange
    </Button>

    <div v-if="isModalVisible">
      <ExchangeModal
        :is-modal-visible="isModalVisible"
        :close-modal="handleCloseModal"
      />
    </div>
  </div>
</template>

<script>
import Button from "../../common/button/button.component.vue";
import ExchangeModal from "../exchange-modal/exchange-modal.component.vue";
import { extractShipmentId } from "../../utils/helpers";
import { isMobileDevice } from "../../utils/helpers";

export default {
  name: "ExchangeButton",
  inject: ["store"],
  components: {
    Button,
    ExchangeModal,
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  mounted() {
    const mainDiv = window.document.getElementById("product-exchange");
    const immediateParent = mainDiv?.parentElement;
    const grandParentDiv = immediateParent?.parentElement;

    mainDiv.style.height = "100%";
    immediateParent.style.height = "100%";
    grandParentDiv.style.flex = "1";

    const isMobile = isMobileDevice();
    this.store.commit("deviceType/SET_IS_MOBILE", isMobile);

    const shipmentId = extractShipmentId();
    this.store.commit("exchangeProduct/SET_SHIPMENT_ID", shipmentId);
  },
  methods: {
    handleExchangeClick() {
      document.body.style.overflow = "hidden";
      this.isModalVisible = true;
    },
    handleCloseModal() {
      // RESET STORE
      this.store.commit("exchangeProduct/RESET_STATE");
      this.store.commit("reasonData/RESET_STATE");
      this.store.commit("addressData/RESET_STATE");
      this.store.commit("deviceType/RESET_STATE");
      this.store.commit("cart/RESET_STATE");

      document.body.style.overflow = "";
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
  .exchange-btn-container{
    height: 100%;
  }
</style>
