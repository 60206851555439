<template>
  <div class="price-breakup-card-container">
    <div v-for="item in cartBreakup" :key="item.key" class="list-item-container">
        <div class="display-text">
            {{ item.display }}
        </div>
        <div class="value-text">
            {{ item.currency_symbol + item.value }}
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PriceBreakupCardContainer",
  props:{
    cartBreakupValues:[]
  },
  computed: {
    cartBreakup(){
        return this.cartBreakupValues;
    }
  }
};
</script>

<style scoped lang="scss">
.price-breakup-card-container { 
    display: flex;
    gap: 6px;
    padding: 12px;
    flex-direction: column;
    border-radius: 4px;
    border: 1px solid #E0E0E0;

    .list-item-container{
        display: flex;
        justify-content: space-between;

        .display-text{
            color:  #373737;
            font-family: unset;
            font-size: 12px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: -0.24px;
        }

        .value-text{
            color: #121212;
            font-family: unset;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.24px;
        }
    }
}
</style>
