<template>
  <div class="address-wrapper">
    <div v-if="this.isLoading">
      <AppLoader
        :showLoadingText="true"
        loadingText="Please Wait Loading Addresses..."
      />
    </div>
    <div v-else class="address-body">
      <div v-for="(address, index) in this.getDisplayedAddresses" :key="index">
        <AddressCard
          :name="address.name"
          :phone="address.phone"
          :address="address.address"
          :label="address.label"
          :pincode="address.pincode"
          :isSelected="selectedAddressIndex === index"
          :buttonText="'Confirm Delivery Address'"
          @select="selectAddress(index)"
          @confirm="confirmAddress"
          @edit="editAddress(index)"
        />
      </div>
      <div class="view-all" @click="viewAll" v-if="this.addresses.length > 2">
        <span class="text">{{
          this.showAll
            ? "View less"
            : `View all ${this.addresses.length} addresses`
        }}</span>
        <img
          :src="downArrowIcon"
          alt="down-arrow-icon"
          :class="{ invert: this.showAll }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddressCard from "../../../../common/address-card/address-card.component.vue";
import AppLoader from "../../../../common/loader/loader.component.vue";

import { getAddresses } from "../../../../services/address.service.js";
import { isUndefinedNullOrEmpty } from "../../../../utils/helpers.js";

import DownArrowIcon from "../../../../assets/down-arrow-icon.svg";

export default {
  name: "DeliveryAddress",
  components: {
    AddressCard,
    AppLoader,
  },
  inject: ["store"],
  props: {
    refreshAddresses: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      downArrowIcon: DownArrowIcon,
      isSameAsPickup: false,
      selectedAddressIndex: null,
      showAll: false,
      addresses: [],
      addressResponse: [],
    };
  },
  async mounted() {
    // Storing the Addresses in the Vue Store after the first call and using it in case of Edit
    if (
      isUndefinedNullOrEmpty(this.store.state.addressData.getDeliveryAddresses)
    ) {
      await this.getUserAddresses();
    } else {
      this.addressResponse = this.store.state.addressData.getDeliveryAddresses;

      this.addresses = this.getFormattedAddresses(this.addressResponse);
    }

    this.isLoading = false;

    if (
      !isUndefinedNullOrEmpty(
        this.store.state.addressData.selectedDeliveryAddress
      )
    ) {
      this.selectedAddressIndex = this.addresses.findIndex(
        (address) =>
          address.id === this.store.state.addressData.selectedDeliveryAddress.id
      );
    } else {
      this.selectedAddressIndex = 0;
    }
  },
  computed: {
    getDisplayedAddresses() {
      return this.showAll ? this.addresses : this.addresses.slice(0, 2);
    },
  },
  watch: {
    refreshAddresses(newValue) {
      if (newValue === true) {
        this.getUserAddresses();
      }

      this.$emit("toggleRefreshAddresses", false);
    },
  },
  methods: {
    selectAddress(index) {
      this.selectedAddressIndex = index;
    },
    editAddress(index) {
      // Adding the address to be edited in store and toggle to edit screen
      const addressToEdit = this.addressResponse[index];

      this.store.commit("addressData/SET_EDIT_ADDRESS", addressToEdit);

      this.showAll = false;

      this.$emit("toggleShowEditAddress", true);
    },
    viewAll() {
      this.showAll = !this.showAll;
    },
    confirmAddress() {
      if (this.selectedAddressIndex !== null) {
        const selectedAddress = this.addressResponse[this.selectedAddressIndex];

        this.store.commit(
          "addressData/SET_SELECTED_DELIVERY_ADDRESS",
          selectedAddress
        );

        this.$emit("complete");
      }
    },
    async getUserAddresses() {
      try {
        const addressResponse = await getAddresses();

        if (addressResponse) {
          this.addressResponse = addressResponse.data.data.addresses.address;

          this.store.commit(
            "addressData/SET_DELIVERY_ADDRESS",
            addressResponse.data.data.addresses.address
          );

          const formattedAddresses = this.getFormattedAddresses(
            addressResponse.data.data.addresses.address
          );

          this.addresses = formattedAddresses;
        }
      } catch (err) {
        console.log("Error in getting address:", err);
      }
    },
    getFormattedAddresses(addresses) {
      return addresses.map((address) => {
        return {
          id: address._id,
          uid: address.uid,
          name: address.name,
          phone: address.phone,
          pincode: address.area_code,
          address:
            address.address +
            ", " +
            address.city +
            ", " +
            address.state +
            " " +
            address.area_code,
          label: address.address_type,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.address-wrapper {
  margin-top: 8px;

  .address-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .input-wrapper {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--Overlay-and-Popup-Dividers-and-Strokes, #e1e1e1);
      background: var(--Theme-White, #fff);
    }

    .view-all {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .text {
        color: #1b6163;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.24px;
      }

      .invert {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
