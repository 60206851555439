import App from "./App.vue";

window.FPI.extension.register("#product-exchange", {
  mounted(element) {
    console.log("window.FPI.extension.register MOUNTED Called", element);
    window.FPI.extension.mountApp({
      element,
      component: App,
    });
  },
});
