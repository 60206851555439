const state = {
    paymentLinkUrl:null,
};

const mutations = {
  SET_PAYMENT_LINK_URL(state, payload) {
    state.paymentLinkUrl = payload;
  },
  RESET_STATE(state) {
    state.paymentLinkUrl = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
