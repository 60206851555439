import axios from "axios";
import { proxyUrl } from "../utils/constants.js";

export const getProductVariants = async (returnProductDetails) => {
  return axios.get(
    `/ext/${proxyUrl}/application/api/v1/products`,
    {
      params:returnProductDetails
    }
  );
};

export const getProductPrice = async (payload) => {
  return axios.get(
    `/ext/${proxyUrl}/application/api/v1/products/${payload.slug}/sizes/${payload.size}/price`,
    {
      headers:{
        'x-pincode':payload.pincode
      }
    }
  );
};