const state = {
  cartValue: null,
};

const mutations = {
  SET_CART_VALUE(state, payload) {
    state.cartValue = payload;
  },
  RESET_STATE(state) {
    state.cartValue = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
