<template>
  <div class="price-breakup-container">
    <div class="loader-wrapper" v-if="this.isLoading">
      <AppLoader
        :showLoadingText="true"
        loadingText="Loading Cart Details..."
      />
    </div>
    <div v-else class="price-breakup">
      <!-- STATUS CARD -->
      <div>
        <ExchangeStatusCard
          :type="pickupCardType"
          :showVerticalLine="true"
          cardText="Once return has been confirmed, we will schedule a pickup ASAP"
        />
        <ExchangeStatusCard
          :type="exchangeCardType"
          :showVerticalLine="false"
          cardText="You will receive the replacement right after pickup"
        />
      </div>
      <!-- PRICE BREAKUP CARD -->
      <div>
        <PriceBreakupCard :cartBreakupValues="cartBreakup" />
      </div>
      <!-- CONFIRM BUTTON -->
      <div>
        <Button
          :button-text="exchangeBtnText"
          :button-class-type="'primary'"
          :aria-label="'price-breakup-confirm-button'"
          :aria-described-by="'price-breakup-confirm-button'"
          @button-click="confirmClick"
          :isDisabled="isExchangeBtnDisabled"
        >
          Exchange Product
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import ExchangeStatusCard from "../../../../common/exchange-status-card/exchange-status-card.component.vue";
import Button from "../../../../common/button/button.component.vue";
import PriceBreakupCard from "../../../../common/price-breakup-card/price-breakup-card.component.vue";
import AppLoader from "../../../../common/loader/loader.component.vue";

import { createCart, checkoutCart } from "../../../../services/cart.service";
import { EXCHANGE_STATUS_CARD_TYPES } from "../../../../utils/constants";
import { isUndefinedNullOrEmpty } from "../../../../utils/helpers";

export default {
  name: "PriceBreakup",
  inject: ["store"],
  components: {
    ExchangeStatusCard,
    Button,
    PriceBreakupCard,
    AppLoader,
  },
  data() {
    return {
      pickupCardType: EXCHANGE_STATUS_CARD_TYPES.PICKUP,
      exchangeCardType: EXCHANGE_STATUS_CARD_TYPES.EXCHANGE,
      cartDetails: null,
      isLoading: true,
      isPlacingOrder: false,
    };
  },
  computed: {
    cartBreakup() {
      return this.cartDetails?.breakup_values?.display ?? [];
    },
    exchangeBtnText() {
      const finalCartAmount = this.cartDetails?.breakup_values?.display.find(
        (breakup) => {
          return breakup?.key === "total";
        }
      );
      
      return finalCartAmount.value > 0 ? `Proceed To Pay ${finalCartAmount.currency_symbol} ${finalCartAmount.value}` : "Exchange Product";
    },
    isExchangeBtnDisabled() {
      return this.isPlacingOrder;
    },
  },
  async mounted() {
    /*
    -> Create Cart API with Price Adjustment if vue store is null
    -> Store cart Response in Vue Store
    */
    const cart = this.store.state.cart.cartValue;
    const selectedVariant =
      this.store.state.exchangeProduct.newExchangeProductVariant;
    const selectedSize =
      this.store.state.exchangeProduct.newExchangeProductSize;

    if (
      isUndefinedNullOrEmpty(cart) ||
      this.isVariantChanged(cart, selectedVariant) ||
      this.isSizeChanged(cart, selectedSize)
    ) {
      await this.createCart();
    }else{
      this.cartDetails = this.store.state.cart.cartValue;
    }
    this.isLoading = false;
  },
  methods: {
    async createCart() {
      /*
        -> Create New Cart
        -> Store it in Vue Store
      */
      try {
        const payload = {
          new_product: {
            id: String(
              this.store.state.exchangeProduct.newExchangeProductVariant.uid
            ),
            size: this.store.state.exchangeProduct.newExchangeProductSize.value,
            forward_address_id: this.getDeliveryAddressId(),
            store_id:
              this.store.state.exchangeProduct.newExchangeProductPriceAndStore
                .store.uid,
          },
          original_product: {
            shipment_id: this.store.state.exchangeProduct.shipmentId,
            bag_id: this.store.state.exchangeProduct.selectedProduct.bag_id,
            line_number:
              this.store.state.exchangeProduct.selectedProduct.line_number,
          },
        };

        const { data } = await createCart(payload);
        this.cartDetails = data.data;
        this.store.state.cart.cartValue = data.data;
      } catch (err) {
        console.log(err);
      }
    },
    getDeliveryAddressId() {
      let deliveryAddressId = null;
      this.store.state.addressData.isPickupSameAsDelivery
        ? (deliveryAddressId =
            this.store.state.addressData.selectedPickupAddress.id)
        : (deliveryAddressId =
            this.store.state.addressData.selectedDeliveryAddress.id);

      return deliveryAddressId;
    },
    isVariantChanged(cart, selectedVariant) {
      const variantInCartUid = cart.items[0].product.uid;
      if (variantInCartUid !== selectedVariant.uid) return true;
      return false;
    },
    isSizeChanged(cart, selectedSize) {
      const sizeInCart = cart.items[0].article.size;
      if (sizeInCart !== selectedSize.value) return true;
      return false;
    },
    openPaymentLink(orderPlaceResponse) {
      const paymentUrl = orderPlaceResponse?.data?.data?.payment_link_url;
      this.store.commit("paymentLink/SET_PAYMENT_LINK_URL", paymentUrl);
      this.$emit("open-payment-link");
    },
    async confirmClick() {
      try {
        this.isPlacingOrder = true;
        let selectedReason = null;
        if (this.store.state.reasonData.selectedSubReason) {
          selectedReason = this.store.state.reasonData.selectedSubReason;
        } else {
          selectedReason = this.store.state.reasonData.selectedReason;
        }

        const payload = {
          exchange_reason: {
            reason: selectedReason,
            medias: this.store.state.reasonData.uploadedImages,
          },
          return_order: {
            product: {
              identifier:
                this.store.state.exchangeProduct.selectedProduct.identifier,
              line_number:
                this.store.state.exchangeProduct.selectedProduct.line_number,
            },
            address_id: this.store.state.addressData.selectedPickupAddress.id,
            original_delivery_address_id:
              this.store.state.exchangeProduct.selectedProduct
                .delivery_address_id,
            shipment: this.store.state.exchangeProduct.shipmentId,
          },
          forward_order: {
            address_id: this.getDeliveryAddressId(),
            cart_id: this.cartDetails?.id,
          },
        };

        const orderPlaceResponse = await checkoutCart(payload);
        if (
          orderPlaceResponse.status === 201 &&
          !orderPlaceResponse?.data?.data?.payment_link_url
        ) {
          this.$emit("complete");
        } else {
          this.openPaymentLink(orderPlaceResponse);
        }
      } catch (err) {
        console.error("error in placing order: ", err);
      } finally {
        this.isPlacingOrder = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.price-breakup-container {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .price-breakup {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
