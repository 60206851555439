<template>
  <button
    type="button"
    :class="[
      buttonClassType === 'primary' ? 'primary-button' : 'secondary-button',
      { disabled: isDisabled },
    ]"
    @click="handleClick"
    :aria-label="ariaLabel"
    :aria-describedby="ariaDescribedBy"
    :disabled="isDisabled"
  >
    <div class="button-text">
      <span>{{ buttonText }}</span>
      <img v-if="showArrowIcon" :src="rightArrowIcon" alt="right-arrow-icon" />
    </div>
  </button>
</template>

<script>
import RightArrowIcon from "../../assets/right-arrow-icon.svg";

export default {
  name: "CommonButton",
  data() {
    return {
      rightArrowIcon: RightArrowIcon,
    };
  },
  props: {
    buttonText: {
      type: String,
      default: "Exchange",
    },
    buttonClassType: {
      type: String,
      default: "primary",
    },
    ariaLabel: {
      type: String,
      default: "Button",
    },
    ariaDescribedBy: {
      type: String,
      default: "button",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showArrowIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (!this.isDisabled) {
        this.$emit("button-click");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.primary-button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--buttonPrimary, #4e3f09);
  width: 100%;
  border: none;
  outline: none;

  color: var(--buttons-secondary, #fff);
  letter-spacing: -0.28px;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  cursor: pointer;
}

.primary-button:hover {
  border-color: var(--buttonPrimaryL1, #816e2a);
  background-color: var(--buttonPrimaryL1, #816e2a);
}

.secondary-button {
  display: flex;
  height: 100%;
  padding: 8px 16px;
  justify-content: center;
  align-items: center; /* align-self: stretch; */
  // border-radius: 4px;
  width: 100%;
  outline: none;
  border: none;
  border-top: 1px solid var(--dividerStokes,#4e3f09);
  border-right: 1px solid var(--dividerStokes,#4e3f09);
  // border: 1px solid var(--buttonPrimary, #4e3f09);
  // color: var(--buttonPrimary, #4e3f09);
  background: transparent;

  letter-spacing: -0.28px;
  text-transform: uppercase;
  // font-size: 14px;
  // font-style: normal;
  // font-weight: 600;
  line-height: 140%; /* 19.6px */
  cursor: pointer;
}

.secondary-button:hover {
  background-color: var(--dividerStokes,#4e3f09);
  // color: #fff;
  // background-color: var(--buttonPrimary, #4e3f09);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-text {
  display: inline-flex;
  align-items: center;
}
</style>
