<template>
  <div class="exchange-global-loader-container">
    <div class="exchange-global-loader">
      <img class="loader-image" :src="this.apiLoader" alt="api_loader" />
    </div>
    <p v-if="showLoadingText" class="loader-text">{{ loadingText }}</p>
  </div>
</template>

<script>
import ApiLoader from "../../assets/api_loader.gif";

export default {
  name: "AppLoader",
  props: {
    loadingText: {
      type: String,
      default: "Please Wait Loading Details...",
    },
    showLoadingText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiLoader: ApiLoader,
    };
  },
};
</script>

<style scoped lang="scss">
.exchange-global-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .exchange-global-loader {
    width: 40px;
    height: 40px;

    .loader-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .loader-text {
    margin-top: 15px;
    font-family: unset;
    font-size: 15px;
    font-weight: unset;
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
}
</style>
