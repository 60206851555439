const state = {
  selectedPickupAddress: {},
  selectedDeliveryAddress: {},
  isPickupSameAsDelivery: true,
  canChangeDeliveryAddress:false,
  showEditAddressScreen: false,
  editAddress: {},
  getPickupAddresses: [],
  getDeliveryAddresses: [],
};

const mutations = {
  SET_SELECTED_PICKUP_ADDRESS(state, payload) {
    state.selectedPickupAddress = payload;
  },
  SET_SELECTED_DELIVERY_ADDRESS(state, payload) {
    if (!state.isPickupSameAsDelivery) {
      state.selectedDeliveryAddress = payload;
    }
  },
  SET_IS_PICKUP_SAME_AS_DELIVERY(state, payload) {
    state.isPickupSameAsDelivery = payload;
  },
  SET_EDIT_ADDRESS(state, payload) {
    state.editAddress = payload;
  },
  SET_EDIT_ADDRESS_SCREEN(state, payload) {
    state.showEditAddressScreen = payload;
  },
  SET_PICKUP_ADDRESS(state, payload) {
    state.getPickupAddresses = payload.apiResponse;
    state.canChangeDeliveryAddress = payload.canChangeDeliveryAddress;
  },
  SET_DELIVERY_ADDRESS(state, payload) {
    state.getDeliveryAddresses = payload;
  },
  RESET_STATE(state) {
    state.selectedPickupAddress = {};
    state.selectedDeliveryAddress = {};
    state.isPickupSameAsDelivery = true;
    state.showEditAddressScreen = false;
    state.editAddress = {};
    state.getPickupAddresses = [];
    state.getDeliveryAddresses = [];
    state.canChangeDeliveryAddress = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
