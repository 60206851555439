<template>
  <div class="payment-link-container">
    <div v-if="showPaymentAnimation" class="payment_animation">
      <img
        class="payment_animation_gif"
        :src="paymentAnimationGif"
        alt="payment_gif"
      />
      <h3 class="payment_processing_text">
        Processing payment for your exchange request...
      </h3>
    </div>
    <iframe
      class="iframe-container"
      v-if="iframeUrl && !showPaymentAnimation"
      :src="iframeUrl"
    ></iframe>
  </div>
</template>

<script>
import PaymentAnimation from "../../../../assets/payment_animation.gif";

export default {
  name: "PaymentLinkFrame",
  inject: ["store"],
  data() {
    return {
      iframeUrl: null,
      showPaymentAnimation: true,
      paymentAnimationGif: PaymentAnimation,
    };
  },
  methods: {
    handleAnimationEnd() {
      this.showPaymentAnimation = false;
    },
  },
  mounted() {
    setTimeout(()=>{
      this.handleAnimationEnd();
    },800);

    if (this.store.state.paymentLink.paymentLinkUrl)
      this.iframeUrl = this.store.state.paymentLink.paymentLinkUrl;
  },
};
</script>

<style scoped lang="scss">
.payment-link-container {
  width: 300px;
  height: 600px;

  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
  }

  .payment_animation {
    height: 100%;
    background: white;
    gap: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .payment_animation_gif {
      width: 180px;
      height: 180px;
    }

    .payment_processing_text {
      color:  #121212;
      text-align: center;
      font-family: unset;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.28px;
    }
  }

  .iframe-container {
    width: 100%;
    height: 100%;
  }
}
</style>
