export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Patterns to detect mobile devices
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent.toLowerCase()
  );
};

export const getTrimmedString = (string, length = 25) => {
  return string.length > length
    ? string.substring(0, length - 3) + "..."
    : string;
};

export const isUndefinedNullOrEmpty = (value) => {
  if (value === undefined || value === null) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return false;
};

export const getCountryName = () => {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const countryCode = locale.split("-")[1] || locale.split("_")[1];

  if (!countryCode) {
    return;
  }

  const regionNames = new Intl.DisplayNames([locale], { type: "region" });
  const countryName = regionNames.of(countryCode);

  return countryName;
};

export const getCountryPhoneCode = () => {
  const countryPhoneCodes = {
    US: "+1",
    GB: "+44",
    FR: "+33",
    DE: "+49",
    CA: "+1",
    IN: "+91",
  };
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;

  const countryCode = locale.split("-")[1] || locale.split("_")[1];

  const phoneCode = countryPhoneCodes[countryCode] || "Unknown";

  return phoneCode;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};


export const extractShipmentId = () =>  {
  const parts = window.location.href.split("/");
  const shipmentId = parts[parts.length - 1];
  return shipmentId;
}

export const getDayAndMonth = (dateString) => {
  const date = new Date(dateString);

  // Get full month name and date
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long', // Full month name
    day: '2-digit', // Two-digit day
  });

  return formattedDate;
}