import axios from "axios";
import { proxyUrl } from "../utils/constants.js";

export const getAddresses = async () => {
  return axios.get(`/ext/${proxyUrl}/application/api/v1/addresses`);
};

export const addAddress = async (addressData) => {
  return axios.post(`/ext/${proxyUrl}/application/api/v1/addresses`, {
    ...addressData,
  });
};

export const editAddress = async (addressData, addressId) => {
  return axios.patch(
    `/ext/${proxyUrl}/application/api/v1/addresses/${addressId}`,
    {
      ...addressData,
    }
  );
};
