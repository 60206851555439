export const PRODUCT_TYPE = {
  RETURN_PRODUCT: "RETURN_PRODUCT",
  EXCHANGE_PRODUCT: "EXCHANGE_PRODUCT",
};

export const MODAL_TYPE = {
  EXCHANGE_MODAL: "EXCHANGE_MODAL",
  CANCEL_MODAL: "CANCEL_MODAL",
};

export const EXCHANGE_STATUS_CARD_TYPES = {
  PICKUP: "PICKUP",
  EXCHANGE: "EXCHANGE",
};

export const ADDRESS_TYPE = {
  PICKUP:"PICKUP",
  DELIVERY:"DELIVERY"
}

export const STOREFRONT_ROUTE_PATHS = {
  HOME_PAGE: "/",
  MY_ORDERS_PAGE: "/profile/orders",
};

// For development can change here
export const proxyUrl = "product-exchange";
